import React from "react"
import { Link } from "gatsby"


export default function Layout({ children }) {
    const CurrentDate = () => {
        return new Date().getFullYear();
    }

    return <div>
        <div className='py-3 h2 bg-dark text-white text-center mb-4'>
            <Link to='/' className="text-white text-decoration-none">Omnomnom</Link> - <span className="">La Tasty Cuisine</span>
        </div>
        <div className="container mx-auto px-3">{children}</div>
        <div className='py-3 bg-dark text-white text-center mt-5'>
            &copy;{CurrentDate()} <Link to="https://omnomnom.stupidus.fr">omnomnom.stupidus.fr</Link>
            <> - </>
            <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
        </div>
    </div>
}
